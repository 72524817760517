$futura-font-path: '~@ru-edu/ecl-player/assets/fonts/futura/';

@font-face {
  font-family: 'Futura PT';
  src: local('Futura PT Light'), local('FuturaPT-Light'), url('#{$futura-font-path}FuturaPT-Light.woff2') format('woff2'),
    url('#{$futura-font-path}FuturaPT-Light.woff') format('woff'),
    url('#{$futura-font-path}FuturaPT-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT';
  src: local('Futura PT Book'), local('FuturaPT-Book'), url('#{$futura-font-path}FuturaPT-Book.woff2') format('woff2'),
    url('#{$futura-font-path}FuturaPT-Book.woff') format('woff'),
    url('#{$futura-font-path}FuturaPT-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT';
  src: local('Futura PT Medium'), local('FuturaPT-Medium'),
    url('#{$futura-font-path}FuturaPT-Medium.woff2') format('woff2'),
    url('#{$futura-font-path}FuturaPT-Medium.woff') format('woff'),
    url('#{$futura-font-path}FuturaPT-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT';
  src: local('Futura PT Bold'), local('FuturaPT-Bold'), url('#{$futura-font-path}FuturaPT-Bold.woff2') format('woff2'),
    url('#{$futura-font-path}FuturaPT-Bold.woff') format('woff'),
    url('#{$futura-font-path}FuturaPT-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
