@use 'sass:string';

@import 'futura-font';

html,
body {
  margin: 0;
  padding: 0;
}

.ecl-player-example {
  height: 100vh;
  font-family: 'Futura PT', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
  Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';

  &, * {
    box-sizing: border-box;
  }

  .example-inner {
    $white: #fff !default;
    $black: #000 !default;

    $bg: $white !default;
    $text: #3d3f46 !default;
    $light: #e0e3e5 !default;
    $muted: #b0b2b4 !default;

    $primary: #0f172a !default;
    $secondary: #41a5ee !default;
    $success: #48b226 !default;
    $warning: #f5c034 !default;
    $danger: #ea4141 !default;

    $border-color: $light !default;
    $border-radius: 0.25rem !default;
    $border-radius-md: 0.5rem !default;
    $border-radius-lg: 0.75rem !default;

    $body-color: $text !default;
    $body-bg: #f8f8fa !default;

    $btn-color: #fff !default;
    $btn-bg: #0f172a !default;

    $colors: (
      primary: $primary,
      secondary: $secondary,
      muted: $muted,
      light: $light,
      success: $success,
      warning: $warning,
      danger: $danger
    ) !default;

    $media-breakpoints: (
      xs: 320px,
      sm: 560px,
      md: 768px,
      lg: 1024px,
      xl: 1440px,
      xxl: 1920px
    ) !default;

    height: 100%;

    .container {
      width: 100%;
      max-width: 1140px;
      margin: 0 auto;
      padding: 0 1rem;
    }

    .navbar {
      min-height: 3.5rem;
      background-color: $bg;
      box-shadow: 0 5px 5px rgba(133, 150, 170, 0.1);

      .navbar-brand {
        text-decoration: none;
        font-size: 1.25rem;

        a {
          color: inherit;
          text-decoration: none;
        }
      }

      .navbar-nav {
        a {
          display: inline-block;
          padding: 0.5rem 0.75rem;
          color: inherit;
          text-decoration: none;
          border-radius: 0.25rem;

          &:last-of-type {
            margin-right: 0;
          }

          &.active {
            background-color: $light;
          }
        }
      }

      &.fixed-top {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
      }
    }

    .flex-row {
      flex-direction: row;
    }

    .flex-column {
      flex-direction: column;
    }

    .flex-grow-0 {
      flex-grow: 0;
    }

    .flex-grow-1 {
      flex-grow: 1;
    }

    .flex-wrap {
      flex-wrap: wrap;
    }

    .form-control {
      appearance: none;
      margin: 0;
      padding: 0.5rem 0.75rem;
      font-size: inherit;
      border: 1px solid $border-color;
      border-radius: $border-radius;
    }

    .btn {
      appearance: none;
      padding: 0.5rem 0.75rem;
      margin: 0;
      font-size: inherit;
      color: $btn-color;
      background-color: $btn-bg;
      border: none;
      border-radius: $border-radius;
      cursor: pointer;

      &:hover {
        background-color: lighten($btn-bg, 10%);
      }
    }

    .visually-hidden {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      border: 0;
      padding: 0;
      white-space: nowrap;
      clip-path: inset(100%);
      clip: rect(0 0 0 0);
      overflow: hidden;
    }

    .text-decoration-none {
      text-decoration: none;
    }

    .card {
      border: 1px solid $border-color;
      padding: 1rem;
      border-radius: $border-radius-md;
      background-color: $white;
    }

    .badge {
      @extend .px-2, .py-1, .text-sm;

      background-color: $light;
      border-radius: $border-radius;
    }

    @each $value in [none, inline, block, inline-block, flex, inline-flex, grid] {
      .d-#{$value} {
        display: $value;
      }
    }

    @each $name, $min-width in $media-breakpoints {
      @media (min-width: $min-width) {
        @each $value in [none, inline, block, inline-block, flex, inline-flex, grid] {
          .d-#{$name}-#{$value} {
            display: $value;
          }
        }
      }
    }

    @each $prop in [margin, padding] {
      $p: string.slice($prop, 1, 1);

      @each $index, $value in (0: 0, 1: 0.25, 2: 0.5, 3: 0.75, 4: 1, 5: 1.5, auto: auto) {
        .#{$p}-#{$index} {
          @if $value == 0 or $value == 'auto' {
            #{$prop}: $value;
          } @else {
            #{$prop}: #{$value}rem;
          }
        }

        .#{$p}x-#{$index} {
          @if $value == 0 or $value == 'auto' {
            #{$prop}-left: $value;
            #{$prop}-right: $value;
          } @else {
            #{$prop}-left: #{$value}rem;
            #{$prop}-right: #{$value}rem;
          }
        }

        .#{$p}y-#{$index} {
          @if $value == 0 or $value == 'auto' {
            #{$prop}-top: $value;
            #{$prop}-bottom: $value;
          } @else {
            #{$prop}-top: #{$value}rem;
            #{$prop}-bottom: #{$value}rem;
          }
        }

        @each $side, $side-prop in (top: top, bottom: bottom, start: left, end: right) {
          $s: string.slice($side, 1, 1);

          .#{$p}#{$s}-#{$index} {
            @if $value == 0 or $value == 'auto' {
              #{$prop}-#{$side-prop}: $value;
            } @else {
              #{$prop}-#{$side-prop}: #{$value}rem;
            }
          }
        }
      }
    }

    @each $size, $value in (25: 25%, 50: 50%, 100: 100%) {
      .w-#{$size} {
        width: $value;
      }

      .h-#{$size} {
        height: $value;
      }
    }

    @each $size in [25, 50, 100] {
      .vw-#{$size} {
        width: #{#size}vw;
      }

      .vh-#{$size} {
        height: #{#size}vh;
      }
    }

    @each $value in [visible, hidden, scroll] {
      .overflow-#{$value} {
        overflow: $value;
      }

      .overflow-x-#{$value} {
        overflow-x: $value;
      }

      .overflow-y-#{$value} {
        overflow-y: $value;
      }
    }

    @each $side, $value in (start: flex-start, end: flex-end, center: center, stretch: stretch) {
      .align-items-#{$side} {
        align-items: $value;
      }
    }

    @each $side, $value in (start: flex-start, end: flex-end, center: center, between: space-between) {
      .justify-content-#{$side} {
        justify-content: $value;
      }
    }

    @each $value in [1, 2, 3, 4] {
      .grid-#{$value} {
        grid-template-columns: repeat($value, 1fr);
        grid-gap: 1rem;
      }
    }

    @each $name, $min-width in $media-breakpoints {
      @media (min-width: $min-width) {
        @each $value in [1, 2, 3, 4] {
          .grid-#{$name}-#{$value} {
            grid-template-columns: repeat($value, 1fr);
            grid-gap: 1rem;
          }
        }
      }
    }

    @each $size, $value in (sm: 0.75rem, md: 1rem, lg: 1.25rem, xl: 1.5rem) {
      .text-#{$size} {
        font-size: $value;
      }
    }

    @each $variant, $color in $colors {
      .text-#{$variant} {
        color: $color;
      }

      .bg-#{$variant} {
        background-color: $color;
      }
    }

    @each $value in [1, 2, 3, 4] {
      .text-lines-#{$value} {
        line-height: 1.25rem;
        max-height: #{1.25 * $value}rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: $value;
        overflow: hidden;
      }
    }
  }

  @-webkit-keyframes spinner-grow {
    0% {
      transform:scale(0)
    }

    50% {
      opacity: 1;
      transform:none
    }
  }

  .spinner {
    background-color: currentColor;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    opacity: 0;
    animation: 0.75s linear infinite spinner-grow
  }

  .loading-indicator {
    @extend .spinner;

    background-color: #e0e3e5;
    width: 4rem;
    height: 4rem;
  }
}

